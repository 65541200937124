.showcase {
    height: auto;
    margin-bottom: 54px;
    margin-top: 54px;
}

@media (max-width: 640px) {
    .showcase {
        height: 300px;
    }
}
